import AlertModal from '@/components/modals/alertModal.vue';
import erroriModal from '@/components/modals/erroriModal.vue';
import ConfirmModal from '@/components/modals/confirmModal.vue';
import InputModal from '@/components/modals/inputModal.vue';
import selectAnagraficaModal from '@/components/modals/selectAnagraficaModal.vue';
import selectUserModal from '@/components/modals/selectUserModal.vue';
import selectImmobiliForecastModal from '@/components/modals/selectImmobiliForecastModal.vue';
class _ModalServices {
    $opModal;
    setOpModal(input) {
        this.$opModal = input;
    }
    selectAnagraficaModal(okCallback) {
        this.$opModal.show(selectAnagraficaModal, {
            okCallback,
        });
    }
    selectImmobiliForecastModal(okCallback) {
        this.$opModal.show(selectImmobiliForecastModal, {
            okCallback,
        });
    }
    selectUserModal(okCallback) {
        this.$opModal.show(selectUserModal, {
            okCallback,
        });
    }
    // selectNameIdModal(list: OM.NameId[], okCallback: (selectedItem: OM.NameId) => void){
    //     this.$opModal.show(selectNameIdModal, {
    //         list,
    //         okCallback,
    //     })
    // }
    alertModal(title, text, okText, okCallback) {
        okText = okText || "OK";
        this.$opModal.show(AlertModal, {
            title,
            text,
            okText,
            okCallback
        });
    }
    erroriModal(title, text, errors) {
        this.$opModal.show(erroriModal, {
            title,
            text,
            errors
        });
    }
    confirmModal(title, text, okText, koText, okCallback, koCallback) {
        okText = okText || "OK";
        this.$opModal.show(ConfirmModal, {
            title,
            text,
            okText,
            koText,
            okCallback,
            koCallback
        });
    }
    inputModal(title, text, okText, koText, value, placeholder, okCallback, koCallback) {
        okText = okText || "OK";
        koText = koText || "Annulla";
        placeholder = placeholder || "Scrivi qui";
        this.$opModal.show(InputModal, {
            title,
            text,
            okText,
            koText,
            value,
            placeholder,
            okCallback,
            koCallback
        });
    }
}
export let ModalServices = new _ModalServices();
