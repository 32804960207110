export class LoginVm {
    email;
    password;
}
export class SearchAnagraficaVm {
    nome;
    cognome;
    codiceFiscale;
    ragioneSociale;
    partitaIva;
    matricola;
    constructor() {
        this.nome = "";
        this.cognome = "";
        this.codiceFiscale = "";
        this.ragioneSociale = "";
        this.partitaIva = "";
        this.matricola = "";
    }
}
export class SearchEstateVm {
    citta;
    centroDiCosto;
    numeroImmobile;
    indirizzo;
    zona;
    assetClass;
    superficieCommercialeMin;
    superficieCommercialeMax;
    createdFrom;
    createdTo;
    constructor() {
        this.citta = "";
        this.centroDiCosto = "";
        this.numeroImmobile = "";
        this.indirizzo = "";
        this.zona = "";
        this.assetClass = "";
        this.superficieCommercialeMin = "";
        this.superficieCommercialeMax = "";
        this.createdFrom = "";
        this.createdTo = "";
    }
}
